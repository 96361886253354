<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Form 2
        </b-card-title>
        <b-card-text class="mb-2">
          This is form 2
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="data.name"
                  :state="errors.length > 0 ? false:null"
                  name="register-name"
                  placeholder="John Doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="data.email"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="data.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    placeholder="604.000.0000"
                    @input="formatPhoneNumber(data.phone, 'phone')"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agent ID"
              label-for="agent-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Agent ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="agent-id"
                    v-model="data.agent_mls_loginName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="agent-id"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Name"
              label-for="office-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Name"
                rules="required"
              >
                <v-select
                  v-model="office"
                  label="officeName"
                  :options="officeOptions"
                  :filterable="false"
                  taggable
                  @search="onSearch"
                  @input="setOffice"
                >
                  <template slot="no-options">
                    <span v-if="didUserType">
                      No results
                    </span>
                    <span v-else>
                      type to search for offices
                    </span>
                  </template>
                  <template
                    v-if="Object.keys(office).length > 0"
                    slot="option"
                    slot-scope="officeOptions"
                  >
                    <div class="d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p v-if="officeOptions.officeShortName && officeOptions.officePhone">
                        {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                      </p>
                    </div>
                  </template>
                  <template
                    v-if="Object.keys(office).length > 0"
                    slot="selected-option"
                    slot-scope="officeOptions"
                  >
                    <div class="selected d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p v-if="Object.keys(officeOptions).length && officeOptions.officeShortName && officeOptions.officePhone">
                        {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                      </p>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="isShowOfficeID()"
              label="Office ID"
              label-for="office-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Office ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-id"
                    v-model="data.agent_office_loginName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-id"
                    readonly
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Phone"
              label-for="office-phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-phone"
                    v-model="data.officeNumber"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-phone"
                    :readonly="isPhoneReadOnly()"
                    @input="formatPhoneNumber(data.officeNumber, 'office-phone')"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Fax"
              label-for="office-fax"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Fax"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-fax"
                    v-model="data.officeFax"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-fax"
                    :readonly="isFaxReadOnly()"
                    @input="formatPhoneNumber(data.officeFax, 'office-fax')"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="data.password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Password Confirm"
              label-for="password-confirm"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|password:@Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirm"
                    v-model="data.password_confirmation"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-alert
              v-if="errorMsg"
              variant="danger"
              show
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BAlert, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    // BSV
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    vSelect,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      officeOptions: [],
      office: {},
      isNoOffice: false,
      data: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        role: 'agent',
        agent_mls_loginName: '',
        memberType: 'agent',
        teamid: '',
        agent_office_loginName: '',
        officeName: '',
        officeNumber: '',
        officeFax: '',
        officeAddr: '',
        officeTel: '',
        status: '',
      },
      required,
      email,
      errorMsg: '',
      didUserType: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    formatPhoneNumber(data, phoneNumberString) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        if (phoneNumberString === 'phone') {
          this.data.phone = match[1] + '.' + match[2] + '.' + match[3]
        } else if (phoneNumberString === 'office-phone') {
          this.data.officeNumber = match[1] + '.' + match[2] + '.' + match[3]
        } else if (phoneNumberString === 'office-fax') {
          this.data.officeFax = match[1] + '.' + match[2] + '.' + match[3]
        }
      }
    },
    setOffice() {
      if (this.office !== null) {
        if (this.officeOptions.length === 0) {
          this.office = { officeName: this.office }
        } else {
          this.data.agent_office_loginName = this.office.officeShortName
          this.data.officeName = this.office.officeName
          this.data.officeNumber = this.office.officePhone
          this.data.officeFax = this.office.officeFax
          this.data.officeAddr = this.office.officeAddress
        }
      } else {
        this.data.agent_office_loginName = ''
        this.data.officeName = ''
        this.data.officeNumber = ''
        this.data.officeFax = ''
        this.data.officeAddr = ''
        this.office = {}
      }
    },
    onSearch(search, loading) {
      if (search && search.trim().length > 1) {
        loading(true)
        this.getOfficeOptions(search, loading)
        this.didUserType = true
      } else {
        this.officeOptions = []
        loading(false)
        this.didUserType = false
      }
    },
    getOfficeOptions(search, loading) {
      this.$store.dispatch('offices/getOfficeNames', { key: search }).then(response => {
        if (response.code === 200) {
          this.officeOptions = response.data
          loading(false)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    noAvailableOffice() {
      this.isNoOffice = true
      this.agent_office_loginName = 'N/A'
    },
    validationForm() {
      this.errorMsg = ''
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          console.log('success')
        }
      })
    },
    isPhoneReadOnly() {
      if (this.office) {
        if (this.office.officeName && !this.office.officePhone && !this.office.officeID) {
          return false
        }
      }

      return true
    },
    isFaxReadOnly() {
      if (this.office) {
        if (this.office.officeName && !this.office.officeFax && !this.office.officeID) {
          return false
        }
      }

      return true
    },
    isShowOfficeID() {
      if (this.office) {
        if (this.office.officeName && !this.office.officeID) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vs__open-indicator{
  display:none;
}
</style>
